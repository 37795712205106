import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  USER_REGISTERING_SET,
  USER_DATA_CLEAN
} from "../../constants/ActionTypes"

import { RequestCONTROLLER } from "util/Logger"
import axios from "util/Api"

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  }
}

//*****************************************************************
//-------------- ------ -- LOGIN / LOGOUT PART  -------------- ------ --
//*****************************************************************

export const userSignIn = ({ email, password, captcha_token }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START })
    axios
      .post("auth/login", {
        email: email,
        password: password,
        captchaToken: captcha_token ? captcha_token : null,
        fcmToken: "dsq"
      })
      .then(({ data }) => {
        if (data.success) {
          localStorage.setItem("token", data.token)
          localStorage.setItem("uid", data.uid)

          dispatch({ type: FETCH_SUCCESS, payload: data.success })
          dispatch({
            type: USER_TOKEN_SET,
            payload: { token: data.token, uid: data.uid }
          })
          window.location.replace("/users")
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.errors })
        }
      })
      .catch(function (error, data) {
        var rep = error.response
        var err = ""

        if (rep === undefined) {
          err = "NETWORK_ERROR"
        } else {
          err = rep.data.errors
          if (err === undefined || err === "" || JSON.stringify(err) === "{}") {
            err = "CREDENTIAL_ERROR"
          }
        }
        dispatch({ type: FETCH_ERROR, payload: err })
      })
  }
}

export const userSignOut = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START })
    localStorage.clear()

    dispatch({
      type: USER_DATA_CLEAN,
      payload: [],
      offset: 0
    })

    axios.post("auth/logout").then(({ data }) => {
      if (data.success) {
        setTimeout(() => {
          localStorage.clear()
          if ("caches" in window) {
            caches.keys().then((names) => {
              // Delete all the cache files
              names.forEach((name) => {
                console.log(name)
                caches.delete(name)
              })
            })
            // Makes sure the page reloads. Changes are only visible after you refresh.
            window.location.reload(true)
          }

          dispatch({ type: FETCH_SUCCESS, payload: data.success })
          dispatch({ type: SIGNOUT_USER_SUCCESS })
        }, 500)
      } else {
        //  console.log(data.error)
        dispatch({ type: FETCH_ERROR, payload: data.error })
      }
    })
  }
}

//*********************************************************************************
//---------------------------------------------------------------------------------
//-------------- ------ --  END AUTH PART  -------------- ------ -----------------
//---------------------------------------------------------------------------------
//*********************************************************************************

//*****************************************************************
//-------------- ------ --  USER PART  -------------- ------ --
//*****************************************************************

export const getUser = (id_compte = "") => {
  return (dispatch) => {
    dispatch({ type: FETCH_START })
    axios
      .get("profile/me")
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS })
          dispatch({ type: USER_DATA, payload: data.user })
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error })
        }
      })
      .catch(function (error) {
        RequestCONTROLLER("ERROR", error, dispatch)
        dispatch({ type: FETCH_ERROR, payload: error.message })
      })
  }
}
