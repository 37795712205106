import toast from 'react-hot-toast';


function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}



export async function RequestCONTROLLER(type, data, dispatch) {

  //DEALING WITH ERRORS
  if (type == "ERROR") {
    if (data.config) {
      //console.log(data.config.url)
      //console.log(data.config.method)
      switch (data.request.status) {
        case 0:

          console.log("NO CONNECTION")
          toast.error("Pas de connexion internet")
          break
        case 400:
          console.log("BAD REQUEST")
          toast.error("Erreur 400, requête invalide")
          break
        case 401:
          localStorage.removeItem("token")
          console.log("AUTHENTIFICATION REQUIRED")
          toast.error("Erreur 401, déconnection")
          break
        case 403:
          console.log("NOT AUTHORIZED")
          toast.error("Erreur 403, non autorisé")
          break
        case 500:
          console.log("INTERNAL_ERROR")
          toast.error("Erreur 500, erreur interne")
          break
        case 502:
          console.log("SERVER_DOWN")
          toast.error("Erreur 502, Serveur injoignable")
          break

        default:
          console.log("NOTING TO DIPLAY")
      }
    } else {
      console.log("Type Error")
      //console.log(data)
    }
  }

  //DEALING WITH SUCCESS SA MERE
  if (type == "SUCCESS") {
    switch (data.type) {
      case 'GET':
        if(data.quantity > 0){
          toast.success(`${data.quantity} elements chargés`)
        }else{
          toast.success(`Aucun élément chargé`)
        }
        break;
      case 'UPDATE':
        toast.success('Element mis à jour avec succès, rechargement automatique')
        await sleep(1000)
        if (!data.noReload)
          window.location.reload()
        break;
      case 'POST':
        toast.success('Nouvel élément crée avec succès, rechargement automatique')
        await sleep(1000)
        if (!data.noReload)
          window.location.reload()
        break;
      default:

    }
  }
}



export function logger(message) {
}
