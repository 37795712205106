import {
  INIT_URL,
  MAPPING_BRANDS_DATA,
  MAPPING_BRANDS_DATA_CLEAN,
  MAPPING_MODELS_DATA,
  MAPPING_MODELS_DATA_CLEAN,
  BRANDS_MODELS_DATA,
  BRANDS_MODELS_DATA_CLEAN
} from "../../constants/ActionTypes"

export const INIT_STATE = {
  mapping_models: [],
  mapping_brands: [],
  car_models_brands: []
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MAPPING_BRANDS_DATA: {
      return {
        ...state,
        mapping_brands: action.payload
      }
    }

    case MAPPING_BRANDS_DATA_CLEAN: {
      return {
        ...state,
        mapping_brands: []
      }
    }

    case MAPPING_MODELS_DATA: {
      return {
        ...state,
        mapping_models: action.payload
      }
    }

    case MAPPING_MODELS_DATA_CLEAN: {
      return {
        ...state,
        mapping_models: []
      }
    }

    case BRANDS_MODELS_DATA: {
      return {
        ...state,
        car_models_brands: action.payload
      }
    }

    case BRANDS_MODELS_DATA_CLEAN: {
      return {
        ...state,
        car_models_brands: []
      }
    }
    default:
      return state
  }
}



