import React from "react"
import { Route, Switch } from "react-router-dom"

import asyncComponent from "util/asyncComponent"

//**************************************************************************
//--------------------------  ODOPASS STAFF ROUTES ---------------------------
//**************************************************************************

/*
export const StaffPowerRoutes = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}facture`} component={asyncComponent(() => import('./FacturePage'))}/>
      <Route path={`${match.url}bon_livraison`} component={asyncComponent(() => import('./BonLivraisonPage'))}/>
      <Route path={`${match.url}adresses`} component={asyncComponent(() => import('./AdressesPage'))}/>
      <Route path={`${match.url}commande`} component={asyncComponent(() => import('./CommandePage'))}/>
      <Route path={`${match.url}commande_fiche`} component={asyncComponent(() => import('./CommandeFiche'))}/>
      <Route path={`${match.url}commande_panier`} component={asyncComponent(() => import('./CommandePanier'))}/>
      <Route path={`${match.url}administration`} component={asyncComponent(() => import('./AdminPage'))}/>
    </Switch>
  </div>
);
*/

//**************************************************************************
//--------------------------  USER CLASSIC ROUTES ---------------------------
//**************************************************************************

export const CompanyAdminRoutes = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}historyRequest`}
        component={asyncComponent(() => import("./VehicleHistoryRequestPage"))}
      />
      <Route path={`${match.url}cars`} component={asyncComponent(() => import("./CarsPage"))} />
      <Route path={`${match.url}users`} component={asyncComponent(() => import("./UsersPage"))} />
      <Route
        path={`${match.url}brandsModels`}
        component={asyncComponent(() => import("./BrandsModelsPage"))}
      />
      {/*<Route path={`${match.url}mappingBrandsModels`} component={asyncComponent(() => import("./MappingBrandsModelsPage"))} />*/}
      <Route
        path={`${match.url}userprofile`}
        component={asyncComponent(() => import("./UserProfilePage"))}
      />
      <Route
        path={`${match.url}subscriptions`}
        component={asyncComponent(() => import("./SubscriptionsPage"))}
      />
      <Route
        path={`${match.url}ownership_transfer`}
        component={asyncComponent(() => import("./OwnershipTransferPage"))}
      />
      <Route
        path={`${match.url}document_verify`}
        component={asyncComponent(() => import("./DocumentVerifyPage"))}
      />
      <Route
        path={`${match.url}reportings`}
        component={asyncComponent(() => import("./ReportingPage"))}
      />
      <Route
        path={`${match.url}changelog`}
        component={asyncComponent(() => import("./ChangelogAppPage"))}
      />
      <Route
        path={`${match.url}events`}
        component={asyncComponent(() => import("./DocEventsPage"))}
      />
      <Route
        path={`${match.url}screens`}
        component={asyncComponent(() => import("./DocScreensPage"))}
      />
      {<Route
        path={`${match.url}cote_marche`}
        component={asyncComponent(() => import("./MarketQuotationsPage"))}
      />}
      {<Route
        path={`${match.url}settings`}
        component={asyncComponent(() => import("./SettingsPage"))}
        />}
      {<Route
        path={`${match.url}gfc_requests`}
        component={asyncComponent(() => import("./GFCRequestsPage"))}
      />}
    </Switch>
  </div>
)
