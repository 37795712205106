import {
  INIT_URL,
  VEHICLEHISTORY_DATA,
  VEHICLEHISTORY_DATA_CLEAN,
  SEARCHED_USER
} from "../../constants/ActionTypes"

export const INIT_STATE = {
  vehicleHistory_LIST: [],
  email: null,
  vehicleHistory_LIST_OFFSET: 0,
  vehicleHistory_LIST_QUANTITY: 20
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case VEHICLEHISTORY_DATA: {
      return {
        ...state,
        vehicleHistory_LIST: removeDuplicates(state.vehicleHistory_LIST.concat(action.payload)),
        vehicleHistory_LIST_OFFSET: action.offset ? action.offset : state.vehicleHistory_LIST_OFFSET
      }
    }

    case VEHICLEHISTORY_DATA_CLEAN: {
      return {
        ...state,
        vehicleHistory_LIST: []
      }
    }
    default:
      return state
  }
}

const by = (property) =>
  function (object) {
    const value = object[property]
    return !(this.has(value) || !this.add(value))
  }

function removeDuplicates(json) {
  return json.filter(by("id_vehicle_history"), new Set())
}
