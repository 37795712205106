const languageData = [
  {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us"
  },
  {
    languageId: "french",
    locale: "fr",
    name: "French",
    icon: "fr"
  }
]
export default languageData
