import React, { Component } from "react"
import { connect } from "react-redux"
import { Menu } from "antd"
import { Link } from "react-router-dom"

import CustomScrollbars from "util/CustomScrollbars"
import SidebarLogo from "./SidebarLogo"

import Auxiliary from "util/Auxiliary"
import UserProfile from "./UserProfile"
import AppsNavigation from "./AppsNavigation"
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting"
import IntlMessages from "../../util/IntlMessages"

class SidebarContent extends Component {
  state = {
    IDCompte: null
  }

  getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications"
    }
    return ""
  }
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup"
    }
    return ""
  }

  componentDidMount() {
    const splitedUrl = window.location.href.split("/")
    const IDCompte = splitedUrl[splitedUrl.length - 1]
    if (parseInt(IDCompte) > 0) {
      this.setState({ IDCompte: IDCompte })
    }
  }

  render() {
    const { themeType, navStyle, pathname, IDEmploye, IDClientStaffSession } = this.props
    const selectedKeys = pathname.substr(1)
    const defaultOpenKeys = selectedKeys.split("/")[1]

    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile />
            <AppsNavigation />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "lite"}
              mode="inline">
              <Menu.Item key="commande">
                <Link to="/commande">
                  <i className="icon icon-shopping-cart" />
                  <IntlMessages id="sidebar.commandes" />
                </Link>
              </Menu.Item>

              <Menu.Item key="bon_livraison">
                <Link to="/bon_livraison">
                  <i className="icon icon-attachment" />
                  <IntlMessages id="sidebar.BL" />
                </Link>
              </Menu.Item>

              <Menu.Item key="facture">
                <Link to="facture">
                  <i className="icon icon-orders" />
                  <IntlMessages id="sidebar.Factures" />
                </Link>
              </Menu.Item>

              <Menu.Item key="adresses">
                <Link to="/adresses">
                  <i className="icon icon-map-google" />
                  <IntlMessages id="sidebar.Adresses" />
                </Link>
              </Menu.Item>
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    )
  }
}

SidebarContent.propTypes = {}
const mapStateToProps = ({ settings, auth }) => {
  const { IDEmploye } = auth
  const { navStyle, themeType, locale, pathname } = settings
  return { navStyle, themeType, locale, pathname, IDEmploye }
}
export default connect(mapStateToProps)(SidebarContent)
