import { MARKETQUOTATION_DATA, MARKETQUOTATION_DATA_CLEAN} from "../../constants/ActionTypes"

export const INIT_STATE = {
  marketQuotationsList: [],
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MARKETQUOTATION_DATA: {
      return {
        ...state,
        marketQuotationsList: removeDuplicates(state.marketQuotationsList.concat(action.payload)),
      }
    }

    case MARKETQUOTATION_DATA_CLEAN: {
      return {
        ...state,
        marketQuotationsList: []
      }
    }

    default:
      return state
  }
}

const by = (property) =>
  function (object) {
    const value = object[property]
    return !(this.has(value) || !this.add(value))
  }

function removeDuplicates(json) {
  return json.filter(by("idCarcote"), new Set())
}
