import {
  INIT_URL,
  REPORTINGLIST_DATA,
  REPORTINGLIST_DATA_CLEAN
} from "../../constants/ActionTypes"

export const INIT_STATE = {
  reporting_LIST: [],

  reporting_LIST_OFFSET: 0,
  reporting_LIST_QUANTITY: 20
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REPORTINGLIST_DATA: {
      return {
        ...state,
        reporting_LIST: removeDuplicates(state.reporting_LIST.concat(action.payload)),
        reporting_LIST_OFFSET: action.offset ? action.offset : state.reporting_LIST_OFFSET
      }
    }

    case REPORTINGLIST_DATA_CLEAN: {
      return {
        ...state,
        reporting_LIST: []
      }
    }
    default:
      return state
  }
}

const by = (property) =>
  function (object) {
    const value = object[property]
    return !(this.has(value) || !this.add(value))
  }

function removeDuplicates(json) {
  return json.filter(by("filename"), new Set())
}
