import {
  INIT_URL,
  STAFF_SESSION_CLIENT,
  USERS_DATA,
  USERS_DATA_CLEAN,
  SEARCHED_USER,
  USERCARS_DATA_CLEAN,
  USERCARS_DATA
} from "../../constants/ActionTypes"

export const INIT_STATE = {
  searched_USER: {},
  users_LIST: [],
  email: null,
  users_LIST_OFFSET: 0,
  users_LIST_QUANTITY: 20,
  userCars_LIST: {}
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEARCHED_USER: {
      return {
        ...state,
        searched_USER: action.payload,
        users_LIST: findAndReplace(state.users_LIST, action.payload.id_user, action.payload)
      }
    }

    case USERCARS_DATA: {
      return {
        ...state,
        userCars_LIST: {
          ...state.userCars_LIST,
          [action.payload.idUser]: action.payload.userCars_LIST
        }
      }
    }

    case USERS_DATA: {
      return {
        ...state,
        users_LIST: removeDuplicates(state.users_LIST.concat(action.payload)),
        users_LIST_OFFSET: action.offset ? action.offset : state.users_LIST_OFFSET
      }
    }

    case USERS_DATA_CLEAN: {
      return {
        ...state,
        users_LIST: []
      }
    }

    case STAFF_SESSION_CLIENT: {
      return {
        ...state,
        IDClientStaffSession: action.payload.IDClientStaffSession,
        email: action.payload.email
      }
    }

    default:
      return state
  }
}

const by = (property) =>
  function (object) {
    const value = object[property]
    return !(this.has(value) || !this.add(value))
  }

function removeDuplicates(json) {
  return json.filter(by("id_user"), new Set())
}


function findAndReplace(list, id_user, replacing_object) {
    return list.map(function (item) {
        if (item.id_user == id_user) {
            item = replacing_object
            return item
        }
    })
}
