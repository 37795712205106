import {
  INIT_URL,
  SUBSCRIPTIONS_DATA,
  SUBSCRIPTIONS_DATA_CLEAN
} from "../../constants/ActionTypes"

export const INIT_STATE = {
  subscription_LIST: [],
  subscription_LIST_OFFSET: 0,
  subscription_LIST_QUANTITY: 20
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUBSCRIPTIONS_DATA: {
      return {
        ...state,
        subscription_LIST: removeDuplicates(state.subscription_LIST.concat(action.payload)),
        subscription_LIST_OFFSET: action.offset ? action.offset : state.subscription_LIST_OFFSET
      }
    }

    case SUBSCRIPTIONS_DATA_CLEAN: {
      return {
        ...state,
        subscription_LIST: []
      }
    }
    default:
      return state
  }
}

const by = (property) =>
  function (object) {
    const value = object[property]
    return !(this.has(value) || !this.add(value))
  }

function removeDuplicates(json) {
  return json.filter(by("id_subscription"), new Set())
}
