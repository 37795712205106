import "./polyfills"

import React from "react"
import ReactDOM from "react-dom"
import NextApp from "./NextApp"
import withClearCache from "./ClearCache"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import registerServiceWorker from "./registerServiceWorker"
// Add this import:
import { AppContainer } from "react-hot-loader"

const ClearCacheComponent = withClearCache(NextApp)

Sentry.init({
  dsn: "https://4c932ba6606c44fb9fcced8c6587de90@o449932.ingest.sentry.io/5583562",
  autoSessionTracking: true,
  environment: process.env.REACT_APP_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
})

// Wrap the rendering in a function:
const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <ClearCacheComponent />
    </AppContainer>,
    document.getElementById("root")
  )
}

// Do this once
registerServiceWorker()
render(NextApp)

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./NextApp", () => {
    render(NextApp)
  })
}
