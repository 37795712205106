import React, { Component } from "react"
import { connect } from "react-redux"
import { Avatar, Popover } from "antd"
import { userSignOut } from "appRedux/actions/Auth"

import static_url from "util/StaticInfo"

// 'https://pro.france-culinaire.com/ressources/images/09eb63f2-91e4-47c0-a633-14592f80ce31.jpg'

// {require(`assets/images/${process.env.REACT_APP_ENV}/profil.jpg`)}

// imageProfil

// 'https://pro.france-culinaire.com/ressources/images/09eb63f2-91e4-47c0-a633-14592f80ce31.jpg'
class UserProfile extends Component {
  RenderImg() {
    return require(`assets/images/${process.env.REACT_APP_ENV}/odologo.jpg`)
  }

  render() {
    const { authUser } = this.props
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => this.props.userSignOut()}>Se déconnecter</li>
      </ul>
    )

    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          <Avatar src={this.RenderImg()} className="gx-size-30 gx-pointer gx-mr-2" alt="" />

          <span className="gx-avatar-name ">
            {authUser ? authUser.Nom + " " + authUser.Prenom : "Administrateur"}
            <i className="icon icon-chevron-down gx-fs-xxxs gx-ml-1" />
          </span>
        </Popover>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth
  return { authUser }
}

export default connect(mapStateToProps, { userSignOut })(UserProfile)
