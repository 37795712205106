import React, { Component } from "react"
import { connect } from "react-redux"
import { Menu } from "antd"
import { Link } from "react-router-dom"

import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../../constants/ThemeSetting"
import IntlMessages from "../../../util/IntlMessages"

const COMPANY_ADMIN = ({ defaultOpenKeys, selectedKeys, themeType }) => (
  <Menu
    defaultOpenKeys={[defaultOpenKeys]}
    selectedKeys={[selectedKeys]}
    theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
    mode="inline">
    <Menu.Item key="users">
      <Link to="/users" style={{ color: '#cccccc' }}>
        <i className="icon icon-user" />
        <IntlMessages id="sidebar.users" />
      </Link>
    </Menu.Item>

    <Menu.Item key="cars">
      <Link to="/cars" style={{ color: '#cccccc' }}>
        <i className="icon icon-shopping-cart" />
        <IntlMessages id="sidebar.cars" />
      </Link>
    </Menu.Item>

    <Menu.Item key="demande historique">
      <Link to="/historyRequest" style={{ color: '#cccccc' }}>
        <i className="icon icon-tasks" />
        <IntlMessages id="sidebar.historyRequest" />
      </Link>
    </Menu.Item>

    <Menu.Item key="brandsModels">
      <Link to="/brandsModels" style={{ color: '#cccccc' }}>
        <i className="icon icon-alert" />
        <IntlMessages icon="camera" id="sidebar.BrandsModels" />
      </Link>
    </Menu.Item>

    {/*<Menu.Item key="mappingBrandsModels">
      <Link to="/mappingBrandsModels" style={{ color: '#cccccc' }}>
        <i className="icon icon-burger" />
        <IntlMessages icon="camera" id="sidebar.MappingBrandsModels" />
      </Link>
</Menu.Item>*/}

    <Menu.Item key="subscriptions">
      <Link to="/subscriptions" style={{ color: '#cccccc' }}>
        <i className="icon icon-star" />
        <IntlMessages id="sidebar.Subscriptions" />
      </Link>
    </Menu.Item>

    <Menu.Item key="Reportings">
      <Link to="/reportings" style={{ color: '#cccccc' }}>
        <i className="icon icon-chart" />
        Reportings
      </Link>
    </Menu.Item>

    <Menu.Item key="transferOwnerships">
      <Link to="/ownership_transfer" style={{ color: '#cccccc' }}>
        <i className="icon icon-transfer"  />
        Demandes de transferts
      </Link>
    </Menu.Item>

    <Menu.Item key="documentVerify">
      <Link to="/document_verify" style={{ color: '#cccccc' }}>
        <i className="icon icon-menu-lines"  />
        Vérification de documents
      </Link>
    </Menu.Item>

    <Menu.Item key="ChangelogApp">
      <Link to="/changelog" style={{ color: '#cccccc' }}>
        <i className="icon icon-tag" />
        Changelog application
      </Link>
    </Menu.Item>

    <Menu.Item key="DocEvents">
      <Link to="/events" style={{ color: '#cccccc' }}>
        <i className="icon icon-upload" />
        Documentation events
      </Link>
    </Menu.Item>

    <Menu.Item key="DocScreens">
      <Link to="/screens" style={{ color: '#cccccc' }}>
        <i className="icon icon-upload" />
        Documentation écrans
      </Link>
    </Menu.Item>

    {<Menu.Item key="marketQuotations">
      <Link to="/cote_marche" style={{ color: '#cccccc' }}>
        <i className="icon icon-pricing-table" />
        Cote Marche Erreur
      </Link>
    </Menu.Item>}

    {<Menu.Item key="Settings">
      <Link to="/settings" style={{ color: '#cccccc' }}>
        <i className="icon icon-view" />
        Settings
      </Link>
    </Menu.Item>}

    {<Menu.Item key="GFCRequests">
      <Link to="/gfc_requests" style={{ color: '#cccccc' }}>
        <i className="icon icon-pricing-table" />
        Chèques carburants
      </Link>
    </Menu.Item>}

  </Menu>
)

export default COMPANY_ADMIN

/*
<Menu.Item key="dashboard">
  <Link to="/dashboard"><i className="icon icon-widgets"/>
    <IntlMessages id="sidebar.dashboard"/></Link>
</Menu.Item>

<Menu.Item key="moncompte">
  <Link to="/moncompte"><i className="icon icon-user"/>
    <IntlMessages id="sidebar.InfoPerso"/></Link>
</Menu.Item>


<Menu.Item key="administration">
  <Link to="/administration"><i className="icon icon-auth-screen"/>
    <IntlMessages id="sidebar.Administration"/></Link>
</Menu.Item>
*/
