import React, { Component } from "react"
import { Layout } from "antd"

import StaffSidebar from "../OverviewStaffSidebar/index"
import Sidebar from "../Sidebar/index"
import HorizontalDefault from "../Topbar/HorizontalDefault/index"
import HorizontalDark from "../Topbar/HorizontalDark/index"
import InsideHeader from "../Topbar/InsideHeader/index"
import AboveHeader from "../Topbar/AboveHeader/index"
import BelowHeader from "../Topbar/BelowHeader/index"
//import ColorPicker from "../ColorPicker";



import Topbar from "../Topbar/index"
import { footerText } from "util/config"
import { CompanyAdminRoutes } from "routes/Routes"

import { connect } from "react-redux"
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting"
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index"

const { Content, Footer } = Layout


export class MainApp extends Component {

  getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap"
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap"
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap"
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap"
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap"
      default:
        return ""
    }
  }
  getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />
      case NAV_STYLE_FIXED:
        return <Topbar />
      case NAV_STYLE_DRAWER:
        return <Topbar />
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar />
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />
      default:
        return null
    }
  }

  getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar />
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <Sidebar />
      case NAV_STYLE_DRAWER:
        return <Sidebar />
      case NAV_STYLE_MINI_SIDEBAR:
        return <Sidebar />
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <Sidebar />
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar />
      default:
        return null
    }
  }

  getStaffSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <StaffSidebar />
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <StaffSidebar />
      case NAV_STYLE_DRAWER:
        return <StaffSidebar />
      case NAV_STYLE_MINI_SIDEBAR:
        return <StaffSidebar />
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <StaffSidebar />
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <StaffSidebar />
      default:
        return null
    }
  }

  AppRouting = (match, scopeLib) => {
    if (scopeLib === undefined) {
      return <CompanyAdminRoutes match={match} />
    } else {
      return <CompanyAdminRoutes match={match} />
    }
  }

  render() {
    const { match, width, navStyle, IDEmploye, scopeLib } = this.props
    return (
      <Layout className="gx-app-layout">
        {this.getSidebar(navStyle, width)}
        <Layout>
          {this.getNavStyles(navStyle)}
          <Content className={`gx-layout-content ${this.getContainerClass(navStyle)} `}>
            {this.AppRouting(match, scopeLib)}
            <Footer>
              <div className="gx-layout-footer-content" style={{ color: 'white' }}>{footerText}</div>

            </Footer>
          </Content>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = ({ settings, auth, clients }) => {
  const { IDEmploye } = auth
  const { width, navStyle } = settings
  return { width, navStyle, IDEmploye }
}
export default connect(mapStateToProps)(MainApp)
