import antdEn from "antd/lib/locale-provider/fr_FR"
import appLocaleData from "react-intl/locale-data/en"
import enMessages from "../locales/fr_FR.json"

const EnLang = {
  messages: {
    ...enMessages
  },
  antd: antdEn,
  locale: "fr-FR",
  data: appLocaleData
}
export default EnLang
