import { INIT_URL, CARS_DATA, CARS_DATA_CLEAN, SEARCHED_CAR, BRANDS_MODELS_DATA } from "../../constants/ActionTypes"

export const INIT_STATE = {
  cars_LIST: [],
  cars_LIST_OFFSET: 0,
  cars_LIST_QUANTITY: 20,
  searched_CAR: {},
  car_models_brands: []
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEARCHED_CAR: {
      return {
        ...state,
        searched_CAR: action.payload
      }
    }

    case CARS_DATA: {
      return {
        ...state,
        cars_LIST: removeDuplicates(state.cars_LIST.concat(action.payload)),
        cars_LIST_OFFSET: action.offset ? action.offset : state.cars_LIST_OFFSET
      }
    }

    case BRANDS_MODELS_DATA: {
      return {
        ...state,
        car_models_brands: action.payload
      }
    }

    case CARS_DATA_CLEAN: {
      return {
        ...state,
        cars_LIST: []
      }
    }

    default:
      return state
  }
}

const by = (property) =>
  function (object) {
    const value = object[property]
    return !(this.has(value) || !this.add(value))
  }

function removeDuplicates(json) {
  return json.filter(by("id_car"), new Set())
}
