import {
  INIT_URL,
  BRANDS_MODELS_DATA,
  BRANDS_MODELS_DATA_CLEAN
} from "../../constants/ActionTypes"

export const INIT_STATE = {
  car_models_brands: [],
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case BRANDS_MODELS_DATA: {
      return {
        ...state,
        car_models_brands: action.payload
      }
    }

    case BRANDS_MODELS_DATA_CLEAN: {
      return {
        ...state,
        car_models_brands: []
      }
    }
    default:
      return state
  }
}



