let baseURL = "http://127.0.0.1:8090/"

if (process.env.REACT_APP_ENV === "staging") {
  baseURL = "https://staging.odopass.tech/api/v4/"
} else if (process.env.REACT_APP_ENV === "developement") {
  baseURL = "http://127.0.0.1:8090/"
} else if (process.env.REACT_APP_ENV === "production") {
  baseURL = "https://pro.odopass.tech/api/v4/"
}

export default baseURL
