import React, { Component } from "react"
import { connect } from "react-redux"
import { Menu , Button} from "antd"
import { Link } from "react-router-dom"

import CustomScrollbars from "util/CustomScrollbars"

import SidebarLogo from "./SidebarLogo"
import SidebarLogoBottom from "./SidebarLogoBottom"

import Auxiliary from "util/Auxiliary"
import UserProfile from "./UserProfile"
import AppsNavigation from "./AppsNavigation"
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting"
import IntlMessages from "../../util/IntlMessages"
import toast, { Toaster } from 'react-hot-toast';

// Menu client
import COMPANY_ADMIN from "./ROLES/COMPANY_ADMIN"



class SidebarContent extends Component {


  getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications"
    }
    return ""
  }

  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup"
    }
    return ""
  }

  GetMenu = (scopeLib, defaultOpenKeys, selectedKeys, themeType) => {
    if (scopeLib === undefined) {
      return (
        <COMPANY_ADMIN
          defaultOpenKeys={defaultOpenKeys}
          selectedKeys={selectedKeys}
          themeType={themeType}
        />
      )
    } else {
      return (
        <COMPANY_ADMIN
          defaultOpenKeys={defaultOpenKeys}
          selectedKeys={selectedKeys}
          themeType={themeType}
        />
      )
    }
  }

  render() {
    const { themeType, navStyle, pathname, IDEmploye, IDClientStaffSession, scopeLib } = this.props
    const selectedKeys = pathname.substr(1)
    const defaultOpenKeys = selectedKeys.split("/")[1]

    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile />
            <AppsNavigation />
            <Toaster />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode="inline">
              {this.GetMenu(scopeLib, defaultOpenKeys, selectedKeys, themeType)}
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    )
  }
}

SidebarContent.propTypes = {}
const mapStateToProps = ({ settings, auth, commonData }) => {
  const { navStyle, themeType, locale, pathname } = settings
  const { loading , message, need_reload, reload_target } = commonData
  return { navStyle, themeType, locale, pathname, loading, message }
}
export default connect(mapStateToProps)(SidebarContent)
