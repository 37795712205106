import saLang from "../../lngProvider/entries/fr_FR"
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE
} from "../../constants/ActionTypes"

const INIT_STATE = {
  error: "",
  loading: false,
  message: ""
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: "", message: "", loading: true }
    }
    case FETCH_SUCCESS: {
      return { ...state, error: "", message: translatePopup(action.payload), loading: false }
    }
    case SHOW_MESSAGE: {
      return { ...state, error: "", message: translatePopup(action.payload), loading: false }
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, error: translatePopup(action.payload), message: "" }
    }
    case HIDE_MESSAGE: {
      return { ...state, loading: false, error: "", message: "" }
    }
    default:
      return state
  }
}

const { messages } = saLang
const arr = Object.keys(messages)

function translatePopup(message) {
  //console.log(message)
  //console.log(messages[message])
  if (messages[message]) {
    const translated = messages[message]
    return translated
  } else {
    return message
  }
}
