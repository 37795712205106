import {
  INIT_URL,
  OWNERSHIPTRANSFER_DATA,
  OWNERSHIPTRANSFER_DATA_CLEAN
} from "../../constants/ActionTypes"

export const INIT_STATE = {
  ownershipTransfer_LIST: [],

  ownershipTransfer_LIST_OFFSET: 0,
  ownershipTransfer_LIST_QUANTITY: 20
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OWNERSHIPTRANSFER_DATA: {
      return {
        ...state,
        ownershipTransfer_LIST: removeDuplicates(state.ownershipTransfer_LIST.concat(action.payload)),
        ownershipTransfer_LIST_OFFSET: action.offset ? action.offset : state.ownershipTransfer_LIST_OFFSET
      }
    }

    case OWNERSHIPTRANSFER_DATA_CLEAN: {
      return {
        ...state,
        ownershipTransfer_LIST: []
      }
    }
    default:
      return state
  }
}

const by = (property) =>
  function (object) {
    const value = object[property]
    return !(this.has(value) || !this.add(value))
  }

function removeDuplicates(json) {
  return json.filter(by("id_ownership_transfer"), new Set())
}
