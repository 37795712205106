import axios from "axios"
import baseURL from "./baseURL"
var axiosConfig = {
  baseURL: process.env.REACT_APP_URL,
  headers: {
    "Content-Type": "application/json"
  }
}

axiosConfig.baseURL = baseURL

export default axios.create(axiosConfig)
