import React, { Component } from "react"
import { connect } from "react-redux"
import { Avatar, Popover } from "antd"
import { userSignOut } from "appRedux/actions/Auth"

class UserInfo extends Component {
  render() {
    const { authUser } = this.props
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li>My Account</li>
        <li onClick={() => this.props.userSignOut()}>Logout</li>
      </ul>
    )

    return (
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <span className="gx-avatar-name ">
          {authUser ? "" : ""}
          <i className="icon icon-chevron-down gx-fs-xxxs gx-ml-1" />
        </span>
      </Popover>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth
  return { authUser }
}

export default connect(mapStateToProps, { userSignOut })(UserInfo)
