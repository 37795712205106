// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU"
export const WINDOW_WIDTH = "WINDOW-WIDTH"
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE"

//Contact Module const
export const FETCH_START = "FETCH_START"
export const FETCH_SUCCESS = "FETCH_SUCCESS"
export const FETCH_ERROR = "FETCH_ERROR"
export const SHOW_MESSAGE = "SHOW_MESSAGE"
export const HIDE_MESSAGE = "HIDE_MESSAGE"
export const ON_SHOW_LOADER = "ON_SHOW_LOADER"
export const ON_HIDE_LOADER = "ON_HIDE_LOADER"

//Auth const
export const SIGNUP_USER = "SIGNUP_USER"
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS"
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER"
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS"
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER"
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS"
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER"
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS"
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER"
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success"
export const SIGNIN_USER = "SIGNIN_USER"
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS"
export const SIGNOUT_USER = "SIGNOUT_USER"
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS"
export const INIT_URL = "INIT_URL"

export const STAFF_SESSION_CLIENT = "STAFF_SESSION_CLIENT"
export const CLIENTS_DATA = "CLIENTS_DATA"
export const CLIENTS_DATA_CLEAN = "CLIENTS_DATA_CLEAN"
export const STAFF_USER_SET = "STAFF_USER_SET"
export const USER_DATA = "USER_DATA"

export const USER_TOKEN_SET = "USER_TOKEN_SET"
export const USER_DATA_CLEAN = "USER_DATA_CLEAN"
export const USER_ITEMS_DATA_CLEAN = "USER_ITEMS_DATA_CLEAN"
export const CLEAR_ONLY_DATA = "CLEAR_ONLY_DATA"

//****************************************************************************************
//----------------------------------------------------------------------------------------
//---------------------------------- OLDCARS CONSTANTS  ------------------------------------
//----------------------------------------------------------------------------------------
//****************************************************************************************

export const OLDCARS_DATA_CLEAN = "OLDCARS_DATA_CLEAN"
export const OLDCARS_DATA = "OLDCARS_DATA"
export const SEARCHED_OLDCAR = "SEARCHED_OLDCAR"

//****************************************************************************************
//----------------------------------------------------------------------------------------
//---------------------------------- CARS CONSTANTS  ------------------------------------
//----------------------------------------------------------------------------------------
//****************************************************************************************

export const CARS_DATA_CLEAN = "CARS_DATA_CLEAN"
export const CARS_DATA = "CARS_DATA"
export const SEARCHED_CAR = "SEARCHED_CAR"

//****************************************************************************************
//----------------------------------------------------------------------------------------
//---------------------------------- USERS CONSTANTS  ------------------------------------
//----------------------------------------------------------------------------------------
//****************************************************************************************

export const USERS_DATA_CLEAN = "USERS_DATA_CLEAN"
export const USERS_DATA = "USERS_DATA"
export const USERCARS_DATA = "USERCARS_DATA"
export const USERCARS_DATA_CLEAN = "USERCARS_DATA_CLEAN"
export const SEARCHED_USER = "SEARCHED_USER"


//****************************************************************************************
//----------------------------------------------------------------------------------------
//----------------------------- SUBSCRIPTIONS CONSTANTS  ------------------------------------
//----------------------------------------------------------------------------------------
//****************************************************************************************

export const SUBSCRIPTIONS_DATA_CLEAN = "SUBSCRIPTIONS_DATA_CLEAN"
export const SUBSCRIPTIONS_DATA = "SUBSCRIPTIONS_DATA"

//****************************************************************************************
//----------------------------------------------------------------------------------------
//----------------------------- BRANDS_MODELS CONSTANTS  ------------------------------------
//----------------------------------------------------------------------------------------
//****************************************************************************************

export const BRANDS_MODELS_DATA_CLEAN = "BRANDS_MODELS_DATA_CLEAN"
export const BRANDS_MODELS_DATA = "BRANDS_MODELS_DATA"

export const MAPPING_BRANDS_DATA_CLEAN = "MAPPING_BRANDS_DATA_CLEAN"
export const MAPPING_BRANDS_DATA = "MAPPING_BRANDS_DATA"
export const MAPPING_MODELS_DATA = "MAPPING_MODELS_DATA"
export const MAPPING_MODELS_DATA_CLEAN = "MAPPING_MODELS_DATA_CLEAN"


//****************************************************************************************
//----------------------------------------------------------------------------------------
//-----------------------------VEHCILE HISTORY CONSTANTS  ------------------------------------
//----------------------------------------------------------------------------------------
//****************************************************************************************

export const VEHICLEHISTORY_DATA_CLEAN = "VEHICLEHISTORY_DATA_CLEAN"
export const VEHICLEHISTORY_DATA = "VEHICLEHISTORY_DATA"

//****************************************************************************************
//----------------------------------------------------------------------------------------
//-----------------------------VEHCILE HISTORY REQUEST CONSTANTS  ------------------------------------
//----------------------------------------------------------------------------------------
//****************************************************************************************

export const VEHICLEHISTORY_REQUEST_DATA = "VEHICLEHISTORY_REQUEST_DATA"
export const VEHICLEHISTORY_AVAILABILITY = "VEHICLEHISTORY_AVAILABILITY"
export const VEHICLEHISTORY_REQUEST_DATA_CLEAN = "VEHICLEHISTORY_REQUEST_DATA_CLEAN"

//****************************************************************************************
//----------------------------------------------------------------------------------------
//------------------------------   MODALS  CONSTANTS  ------------------------------------
//----------------------------------------------------------------------------------------
//****************************************************************************************

export const DISPLAY_LINK_VEHICLEHISTORY_MODAL = "DISPLAY_LINK_VEHICLEHISTORY_MODAL"
export const DISMISS_LINK_VEHICLEHISTORY_MODAL = "DISMISS_LINK_VEHICLEHISTORY_MODAL"

export const DISPLAY_ADD_CARDOCUMENT_MODAL = "DISPLAY_ADD_CARDOCUMENT_MODAL"
export const DISMISS_ADD_CARDOCUMENT_MODAL = "DISMISS_ADD_CARDOCUMENT_MODAL"

export const DISPLAY_ADD_PROFILEDOCUMENT_MODAL = "DISPLAY_ADD_PROFILEDOCUMENT_MODAL"
export const DISMISS_ADD_PROFILEDOCUMENT_MODAL = "DISMISS_ADD_PROFILEDOCUMENT_MODAL"

export const DISPLAY_CREATE_USER_MODAL = "DISPLAY_CREATE_USER_MODAL"
export const DISMISS_CREATE_USER_MODAL = "DISMISS_CREATE_USER_MODAL"

export const DISPLAY_CREATE_CAR_MODAL = "DISPLAY_CREATE_CAR_MODAL"
export const DISMISS_CREATE_CAR_MODAL = "DISMISS_CREATE_CAR_MODAL"

export const DISPLAY_CREATE_PRICING_MODAL = 'DISPLAY_CREATE_PRICING_MODAL'
export const DISMISS_CREATE_PRICING_MODAL = 'DISMISS_CREATE_PRICING_MODAL'

//****************************************************************************************
//----------------------------------------------------------------------------------------
//------------------------------   ALERTS CONSTANTS  ------------------------------------
//----------------------------------------------------------------------------------------
//****************************************************************************************

export const DISPLAY_CONFIRM_LINKHISTORY_ALERT = "DISPLAY_CONFIRM_LINKHISTORY_ALERT"
export const DISMISS_CONFIRM_LINKHISTORY_ALERT = "DISMISS_CONFIRM_LINKHISTORY_ALERT"

export const DISPLAY_BAN_USER_ALERT = "DISPLAY_BAN_USER_ALERT"
export const DISMISS_BAN_USER_ALERT = "DISMISS_BAN_USER_ALERT"

export const DISPLAY_BAN_CAR_ALERT = "DISPLAY_BAN_CAR_ALERT"
export const DISMISS_BAN_CAR_ALERT = "DISMISS_BAN_CAR_ALERT"

export const DISPLAY_DELETE_EXPENSE_ALERT = "DISPLAY_DELETE_EXPENSE_ALERT"
export const DISMISS_DELETE_EXPENSE_ALERT = "DISMISS_DELETE_EXPENSE_ALERT"

export const DISPLAY_DELETE_CARDOCUMENT_ALERT = "DISPLAY_DELETE_CARDOCUMENT_ALERT"
export const DISMISS_DELETE_CARDOCUMENT_ALERT = "DISMISS_DELETE_CARDOCUMENT_ALERT"

export const DISPLAY_DELETE_USERDOCUMENT_ALERT = "DISPLAY_DELETE_USERDOCUMENT_ALERT"
export const DISMISS_DELETE_USERDOCUMENT_ALERT = "DISMISS_DELETE_USERDOCUMENT_ALERT"

export const DISPLAY_CREATE_ARTICLE_MODAL = "DISPLAY_CREATE_ARTICLE_MODAL"
export const DISMISS_CREATE_ARTICLE_MODAL = "DISMISS_CREATE_ARTICLE_MODAL"

//****************************************************************************************
//----------------------------------------------------------------------------------------
//---------------------------   OWNERSHIP TRANSFER CONSTANTS  ----------------------------
//----------------------------------------------------------------------------------------
//****************************************************************************************


export const OWNERSHIPTRANSFER_DATA = 'OWNERSHIPTRANSFER_DATA'
export const OWNERSHIPTRANSFER_DATA_CLEAN = 'OWNERSHIPTRANSFER_DATA_CLEAN'


//****************************************************************************************
//----------------------------------------------------------------------------------------
//-----------------------------   SETTINGS LIST CONSTANTS  --------------------------------
//----------------------------------------------------------------------------------------
//****************************************************************************************


export const SETTINGS_DATA = 'SETTINGS_DATA'
export const SETTINGS_DATA_CLEAN = 'SETTINGS_DATA_CLEAN'



//****************************************************************************************
//----------------------------------------------------------------------------------------
//-----------------------------   REPORTINGLIST LIST CONSTANTS  --------------------------------
//----------------------------------------------------------------------------------------
//****************************************************************************************


export const REPORTINGLIST_DATA = 'REPORTINGLIST_DATA'
export const REPORTINGLIST_DATA_CLEAN = 'REPORTINGLIST_DATA_CLEAN'



//****************************************************************************************
//----------------------------------------------------------------------------------------
//-----------------------------   ARTICLELIST LIST CONSTANTS  --------------------------------
//----------------------------------------------------------------------------------------
//****************************************************************************************


export const MARKETQUOTATION_DATA = 'MARKETQUOTATION_DATA'
export const MARKETQUOTATION_DATA_CLEAN = 'MARKETQUOTATION_DATA_CLEAN'

//****************************************************************************************
//----------------------------------------------------------------------------------------
//---------------------------   OWNERSHIP TRANSFER CONSTANTS  ----------------------------
//----------------------------------------------------------------------------------------
//****************************************************************************************


export const DOCUMENT_VERIFY_DATA = 'DOCUMENT_VERIFY_DATA'
export const DOCUMENT_VERIFY_DATA_CLEAN = 'DOCUMENT_VERIFY_DATA_CLEAN'


export const CTCONFLICT_DATA = 'CTCONFLICT_DATA';
export const CTCONFLICT_DATA_CLEAN = 'CTCONFLICT_DATA_CLEAN';

export const GFC_REQUESTS_DATA = 'GFC_REQUESTS_DATA';
export const GFC_REQUESTS_DATA_CLEAN = 'GFC_REQUESTS_DATA_CLEAN';