import React from "react";
import {Button, Form, Input} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {userSignIn} from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";


let SITE_KEY = "6LdFw-UZAAAAACUlaqAPuIL8QR-rk3sRzUJVoY7n"

if (process.env.REACT_APP_ENV === "developement"){
  SITE_KEY= "6LdFw-UZAAAAACUlaqAPuIL8QR-rk3sRzUJVoY7n"
}

const DELAY = 1000
const FormItem = Form.Item;

class SignIn extends React.Component {

  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      callback: "not fired",
      value: "[empty]",
      load: false,
      expired: "false",
      _reCaptchaRef:null
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.captcha_token = this.state.value
        this.props.userSignIn(values);
      }
    });
  };


  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push('/');
    }
    setTimeout(() => {
      this.setState({ load: true });
    }, DELAY);
  }


  handleChange = value => {
    this.setState({ value });
    if (value === null) this.setState({ expired: "true" });
  };


  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });
  };


// <img alt="example" src={require(`assets/images/${process.env.REACT_APP_ENV}/ydeo_connect.png`)}/>
// <img alt="example" src={require(`assets/images/${process.env.REACT_APP_ENV}/logo.png`)}/>
  render() {
    const {getFieldDecorator} = this.props.form;
    return (
      <>
      <div className="gx-app-login-wrap" style={{ backgroundColor: '#656565'}}>
        <div className="gx-app-login-container" >
          <div className="gx-app-login-main-content" >
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-wid">
              <div className="gx-app-logo">
                <img alt="example" style = {{marginBottom : 30}} src={require(`assets/images/${process.env.REACT_APP_ENV}/odopass.png`)}/>
              </div>
                <div className = "gx-text-center gx-flex-column gx-justify-content-center">
                  <h1><IntlMessages id="app.userAuth.signIn"/></h1>
                  <p><IntlMessages id="app.userAuth.bySigning"/></p>
                </div>
              </div>
            </div>
            <div style={{backgroundColor:"#dddddd"}} className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('email', {
                    initialValue: "",
                    rules: [{
                      required: true, type: 'email', message: `Cette adresse e-mail n'est pas valide`,
                    }],
                  })(
                    <Input placeholder="Email"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    initialValue: "",
                    rules: [{required: true, message: 'Entrer votre mot de passe'}],
                  })(
                    <Input type="password" placeholder="Mot de passe"/>
                  )}
                </FormItem>
                {this.state.load && (
                    <ReCAPTCHA
                      style={{ display: "inline-block" }}
                      theme="white"
                      ref={this.state._reCaptchaRef}
                      sitekey={SITE_KEY}
                      onChange={this.handleChange}
                      asyncScriptOnLoad={this.asyncScriptOnLoad}
                    />
                  )}


                <FormItem>
                  <Button background-color="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn"/>
                  </Button>
                  <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/reset-password"><IntlMessages
                  id="app.userAuth.forgetPassword"/></Link>
                </FormItem>
                <span className="gx-text-light gx-fs-sm"> <IntlMessages id="app.userAuth.infologin"/></span>

              </Form>
            </div>
            <InfoView/>
          </div>
        </div>
      </div>
      </>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {userSignIn})(WrappedNormalLoginForm);
