import {
  INIT_URL,
  SETTINGS_DATA,
  SETTINGS_DATA_CLEAN
} from "../../constants/ActionTypes"

export const INIT_STATE = {
  settingsList: [],
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SETTINGS_DATA: {
      return {
        ...state,
        settingsList: removeDuplicates(state.settingsList.concat(action.payload)),
      }
    }

    case SETTINGS_DATA_CLEAN: {
      return {
        ...state,
        settingsList: []
      }
    }
    default:
      return state
  }
}

const by = (property) =>
  function (object) {
    const value = object[property]
    return !(this.has(value) || !this.add(value))
  }

function removeDuplicates(json) {
  return json.filter(by("settingName"), new Set())
}
