import saLang from "../../lngProvider/entries/fr_FR"

import {
  DISPLAY_LINK_VEHICLEHISTORY_MODAL,
  DISMISS_LINK_VEHICLEHISTORY_MODAL,
  DISPLAY_CREATE_USER_MODAL,
  DISMISS_CREATE_USER_MODAL,
  DISPLAY_ADD_CARDOCUMENT_MODAL,
  DISMISS_ADD_CARDOCUMENT_MODAL,
  DISPLAY_ADD_PROFILEDOCUMENT_MODAL,
  DISMISS_ADD_PROFILEDOCUMENT_MODAL,
  DISPLAY_CREATE_CAR_MODAL,
  DISMISS_CREATE_CAR_MODAL,
  DISPLAY_CREATE_PRICING_MODAL,
  DISMISS_CREATE_PRICING_MODAL,
  DISPLAY_CREATE_ARTICLE_MODAL,
  DISMISS_CREATE_ARTICLE_MODAL
} from "../../constants/ActionTypes"

const INIT_STATE = {
  linkVehicleHistoryModal: false,
  showCreateUserModal: false,
  showAddCarDocumentModal: false,
  showAddProfileDocumentModal: false,
  showCreateCarModal: false,
  showNewPricingModal: false,
  showCreateNewArticle: false
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DISPLAY_ADD_PROFILEDOCUMENT_MODAL: {
      return { ...state, showAddProfileDocumentModal: true }
    }
    case DISMISS_ADD_PROFILEDOCUMENT_MODAL: {
      return { ...state, showAddProfileDocumentModal: false }
    }

    case DISPLAY_CREATE_CAR_MODAL: {
      return { ...state, showCreateCarModal: true }
    }
    case DISMISS_CREATE_CAR_MODAL: {
      return { ...state, showCreateCarModal: false }
    }

    case DISPLAY_CREATE_USER_MODAL: {
      return { ...state, showCreateUserModal: true }
    }
    case DISMISS_CREATE_USER_MODAL: {
      return { ...state, showCreateUserModal: false }
    }

    case DISPLAY_ADD_CARDOCUMENT_MODAL: {
      return { ...state, showAddCarDocumentModal: true }
    }
    case DISMISS_ADD_CARDOCUMENT_MODAL: {
      return { ...state, showAddCarDocumentModal: false }
    }

    case DISPLAY_LINK_VEHICLEHISTORY_MODAL: {
      return { ...state, linkVehicleHistoryModal: true }
    }
    case DISMISS_LINK_VEHICLEHISTORY_MODAL: {
      return { ...state, linkVehicleHistoryModal: false }
    }

    case DISPLAY_CREATE_PRICING_MODAL: {
      return { ...state, showNewPricingModal: true }
    }
    case DISMISS_CREATE_PRICING_MODAL: {
      return { ...state, showNewPricingModal: false }
    }

    case DISPLAY_CREATE_ARTICLE_MODAL: {
      return { ...state, showCreateNewArticle: true }
    }
    case DISMISS_CREATE_ARTICLE_MODAL: {
      return { ...state, showCreateNewArticle: false }
    }


    default:
      return state
  }
}
