import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes"

export const INIT_STATE = {
  token: localStorage.getItem("token"),
  IDEmploye: localStorage.getItem("staff_member"),
  guid: "",
  initURL: "",
  authUser: ""
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return { ...state, initURL: action.payload }
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: ""
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload
      }
    }

    default:
      return state
  }
}
