import React, { Component } from "react"
import { connect } from "react-redux"
import { Menu } from "antd"
import { Link } from "react-router-dom"
import IntlMessages from "../../util/IntlMessages"
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting"

const SubMenu = Menu.SubMenu

class HorizontalNav extends Component {
  getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve"
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve"
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve"
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve"
      default:
        return "gx-menu-horizontal"
    }
  }

  render() {
    const { pathname, navStyle } = this.props
    const selectedKeys = pathname.substr(1)
    const defaultOpenKeys = selectedKeys.split("/")[1]
    return (
      <Menu defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]} mode="horizontal">
        <Menu.Item key="dashboard">
          <Link to="/dashboard">
            <i className="icon icon-widgets" />
            <IntlMessages id="sidebar.dashboard" />
          </Link>
        </Menu.Item>

        <Menu.Item key="commande">
          <Link to="/commande">
            <i className="icon icon-shopping-cart" />
            <IntlMessages id="sidebar.commandes" />
          </Link>
        </Menu.Item>

        <Menu.Item key="bon_livraison">
          <Link to="/bon_livraison">
            <i className="icon icon-attachment" />
            <IntlMessages id="sidebar.BL" />
          </Link>
        </Menu.Item>

        <Menu.Item key="facture">
          <Link to="facture">
            <i className="icon icon-orders" />
            <IntlMessages id="sidebar.Factures" />
          </Link>
        </Menu.Item>

        <Menu.Item key="adresses">
          <Link to="/adresses">
            <i className="icon icon-map-google" />
            <IntlMessages id="sidebar.Adresses" />
          </Link>
        </Menu.Item>

        <Menu.Item key="moncompte">
          <Link to="/moncompte">
            <i className="gx-menu-horizontal icon icon-user" />
            <IntlMessages id="sidebar.InfoPerso" />
          </Link>
        </Menu.Item>

        <Menu.Item key="admin" className={this.getNavStyleSubMenuClass(navStyle)}>
          <Link to="/admin">
            <i className="icon icon-user" />
            <IntlMessages id="sidebar.Admin" />
          </Link>
        </Menu.Item>
        <SubMenu
          className={this.getNavStyleSubMenuClass(navStyle)}
          key="main"
          title={<IntlMessages id="sidebar.main" />}>
          <Menu.Item key="sample">
            <Link to="/sample">
              <i className="icon icon-widgets" />
              <IntlMessages id="sidebar.samplePage" />
            </Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    )
  }
}

HorizontalNav.propTypes = {}
const mapStateToProps = ({ settings }) => {
  const { themeType, navStyle, pathname, locale } = settings
  return { themeType, navStyle, pathname, locale }
}
export default connect(mapStateToProps)(HorizontalNav)
