import { CTCONFLICT_DATA, CTCONFLICT_DATA_CLEAN } from "../../constants/ActionTypes"

export const INIT_STATE = {
  manualCTExpense: null,
  automaticCTExpenses: [],
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CTCONFLICT_DATA: {
      return {
        ...state,
        manualCTExpense: action.payload.manualCTExpense,
        automaticCTExpenses: removeDuplicates(state.automaticCTExpenses.concat(action.payload.automaticCTExpenses)),
      }
    }

    case CTCONFLICT_DATA_CLEAN: {
      return {
        ...state,
        manualCTExpense: null,
        automaticCTExpenses: [],
      }
    }

    default:
      return state
  }
}

const by = (property) =>
  function (object) {
    const value = object[property]
    return !(this.has(value) || !this.add(value))
  }

function removeDuplicates(json) {
  return json.filter(by("idExpense"), new Set())
}
