import {
  INIT_URL,
  VEHICLEHISTORY_REQUEST_DATA,
  VEHICLEHISTORY_AVAILABILITY,
  VEHICLEHISTORY_REQUEST_DATA_CLEAN,
  SEARCHED_USER
} from "../../constants/ActionTypes"

export const INIT_STATE = {
  vehicleHistoryRequest_LIST: [],
  email: null,
  vehicleHistoryRequest_LIST_OFFSET: 0,
  vehicleHistoryRequest_LIST_QUANTITY: 20,
  inMaintenance: null,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case VEHICLEHISTORY_REQUEST_DATA: {
      return {
        ...state,
        vehicleHistoryRequest_LIST: removeDuplicates(
          state.vehicleHistoryRequest_LIST.concat(action.payload)
        ),
        vehicleHistoryRequest_LIST_OFFSET: action.offset
      }
    }

    case VEHICLEHISTORY_AVAILABILITY: {
      return {
        ...state,
        inMaintenance: action.payload,
      }
    }

    case VEHICLEHISTORY_REQUEST_DATA_CLEAN: {
      return {
        ...state,
        vehicleHistoryRequest_LIST: []
      }
    }
    default:
      return state
  }
}

const by = (property) =>
  function (object) {
    const value = object[property]
    return !(this.has(value) || !this.add(value))
  }

function removeDuplicates(json) {
  return json.filter(by("id_vehiclehistory_request"), new Set())
}
