import saLang from "../../lngProvider/entries/fr_FR"

import {
  DISPLAY_CONFIRM_LINKHISTORY_ALERT,
  DISMISS_CONFIRM_LINKHISTORY_ALERT,
  DISPLAY_BAN_USER_ALERT,
  DISMISS_BAN_USER_ALERT,
  DISPLAY_BAN_CAR_ALERT,
  DISMISS_BAN_CAR_ALERT,
  DISPLAY_DELETE_EXPENSE_ALERT,
  DISMISS_DELETE_EXPENSE_ALERT,
  DISPLAY_DELETE_CARDOCUMENT_ALERT,
  DISMISS_DELETE_CARDOCUMENT_ALERT,
  DISPLAY_DELETE_USERDOCUMENT_ALERT,
  DISMISS_DELETE_USERDOCUMENT_ALERT
} from "../../constants/ActionTypes"

const INIT_STATE = {
  confirmLinkHistoryAlert: false,
  confirmBanUserAlert: false,
  confirmBanCarAlert: false,
  confirmDeleteExpenseAlert: false,
  confirmDeleteCarDocumentAlert: false,
  confirmDeleteUserDocumentAlert: false,
  request_meta: {} //PASS PARAMS TO ALERTS
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DISPLAY_DELETE_USERDOCUMENT_ALERT: {
      return { ...state, confirmDeleteUserDocumentAlert: true, request_meta: action.payload }
    }
    case DISMISS_DELETE_USERDOCUMENT_ALERT: {
      return { ...state, confirmDeleteUserDocumentAlert: false, request_meta: {} }
    }

    case DISPLAY_DELETE_CARDOCUMENT_ALERT: {
      return { ...state, confirmDeleteCarDocumentAlert: true, request_meta: action.payload }
    }
    case DISMISS_DELETE_CARDOCUMENT_ALERT: {
      return { ...state, confirmDeleteCarDocumentAlert: false, request_meta: {} }
    }

    case DISPLAY_DELETE_EXPENSE_ALERT: {
      return { ...state, confirmDeleteExpenseAlert: true, request_meta: action.payload }
    }
    case DISMISS_DELETE_EXPENSE_ALERT: {
      return { ...state, confirmDeleteExpenseAlert: false, request_meta: {} }
    }

    case DISPLAY_BAN_USER_ALERT: {
      return { ...state, confirmBanUserAlert: true, request_meta: action.payload }
    }
    case DISMISS_BAN_USER_ALERT: {
      return { ...state, confirmBanUserAlert: false, request_meta: {} }
    }

    case DISPLAY_BAN_CAR_ALERT: {
      return { ...state, confirmBanCarAlert: true, request_meta: action.payload }
    }
    case DISMISS_BAN_CAR_ALERT: {
      return { ...state, confirmBanCarAlert: false, request_meta: {} }
    }

    case DISPLAY_CONFIRM_LINKHISTORY_ALERT: {
      return { ...state, confirmLinkHistoryAlert: true, request_meta: action.payload }
    }
    case DISMISS_CONFIRM_LINKHISTORY_ALERT: {
      return { ...state, confirmLinkHistoryAlert: false, request_meta: {} }
    }

    default:
      return state
  }
}
