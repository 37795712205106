import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"
import Settings from "./Settings"
import Auth from "./Auth"
import Common from "./Common"

import MarketQuotations from "./MarketQuotations"
import CTConflicts from "./CTConflicts"
import Cars from "./Cars"
import Users from "./Users"
import VehicleHistory from "./VehicleHistory"
import VehicleHistoryRequest from "./VehicleHistoryRequest"
import Modals from "./Modals"
import Alerts from "./Alerts"
import BrandsModels from './BrandsModels'
import MappingBrandsModels from './MappingBrandsModels'
import Subscriptions from './Subscriptions'
import OwnershipTransfer from './OwnershipTransfer'
import AppSettings from './AppSettings'
import Reportings from './Reportings'
import DocumentVerify from "./DocumentVerify"
import GFCRequests from "./GFCRequests"

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  alerts: Alerts,
  commonData: Common,
  cars: Cars,
  marketQuotations: MarketQuotations,
  CTConflicts: CTConflicts,
  users: Users,
  vehicle_history: VehicleHistory,
  vehicle_history_request: VehicleHistoryRequest,
  modals: Modals,
  brandsModels: BrandsModels,
  mappingBrandsModels: MappingBrandsModels,
  subscriptions: Subscriptions,
  ownership_transfer: OwnershipTransfer,
  document_verify: DocumentVerify,
  gfc_requests: GFCRequests,
  appSettings: AppSettings,
  reportings: Reportings
})

export default reducers
